import * as React from "react";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";

export const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+{7}(000)000-00-00"
      placeholderChar="(000)000-00-00"
      // definitions={{
      //   "#": /[1-9]/,
      // }}
      min={10}
      max={11}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
