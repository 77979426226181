// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/examples/tv-repair-blog.jpg";
import post2 from "assets/images/examples/book-repair-blog.jpg";
import post3 from "assets/images/examples/pc-repair-blog.jpg";
import BackgroundBlogCard from "../../../../examples/Cards/BlogCards/BackgroundBlogCard";
import post4 from "assets/images/examples/blog2.jpg";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Все статьи
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post1}
              title="Ремонт телевизоров: Как самостоятельно решить некоторые проблемы"
              description="Вместо того, чтобы сразу обращаться к специалистам, есть несколько общих проблем,
               которые можно попытаться решить самостоятельно."
              action={{
                type: "internal",
                route: "/blog/repair-tv",
                color: "info",
                label: "Далее...",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Ремонт ноутбуков: Эффективные советы и простые решения"
              description="В этой статье мы рассмотрим несколько типичных проблем и предоставим вам полезные советы по их устранению"
              action={{
                type: "internal",
                route: "/blog/repair-notebook",
                color: "info",
                label: "Далее...",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title="Ремонт компьютеров: Самостоятельное решение типичных проблем"
              description="Полезные советы для самостоятельного не сложного ремонта своего компьютера"
              action={{
                type: "internal",
                route: "/blog/repair-pc",
                color: "info",
                label: "Далее...",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post4}
              title="Установка спутникого оборудования"
              description="Профессиональные услуги по установке, настройке и обслуживанию спутникового оборудования"
              action={{
                type: "internal",
                route: "/service.js/install-satellite",
                label: "Далее...",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
