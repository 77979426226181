/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { lazy, Suspense } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import CircularProgress from "@mui/material/CircularProgress";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
//const OtherComponent = loadable(() => import('./OtherComponent'))
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
// const Information = lazy(() => import("pages/AboutUs/sections/Information"));
import Information from "pages/AboutUs/sections/Information";
// const Team = lazy(() => import("pages/AboutUs/sections/Team"));
import Team from "pages/AboutUs/sections/Team";
// const Featuring = lazy(() => import("pages/AboutUs/sections/Featuring"));
import Featuring from "pages/AboutUs/sections/Featuring";
// const Newsletter = lazy(() => import("pages/AboutUs/sections/Newsletter"));
import Newsletter from "pages/AboutUs/sections/Newsletter";
import Content from "pages/AboutUs/sections/Content";
// const Content = lazy(() => import("pages/AboutUs/sections/Content"));

// Routes
import { navBarRoutes } from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/manfettler.webp";

function AboutUs() {
  // const renderLoader = () => <CircularProgress />;
  return (
    <>
      <DefaultNavbar
        routes={navBarRoutes}
        // action={{
        //   type: "external",
        //   route: "tel:+79031924437",
        //   label: "89031924437",
        //   color: "default",
        // }}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Сервисный центр по ремонту телевизоров и ноутбуков
            </MKTypography>
            <MKTypography variant="h4" color="white" opacity={0.8} mt={1} mb={3}>
              Ремонтируем электронику с бесплатной диагностикой и выездом на дом: Домодедово,
              Саратов
            </MKTypography>
            {/*<MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>*/}
            {/*  вызвать мастера*/}
            {/*</MKButton>*/}
            <MKTypography
              component="a"
              variant="body1"
              color="white"
              href="tel:+79933251262"
              mr={3}
            >
              8-993-325-12-62
            </MKTypography>
            <MKTypography variant="h6" color="white" mt={4} mb={1}>
              Напишите нам в мессенджер
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography
                component="a"
                variant="h3"
                color="white"
                href="https://t.me/repairer_pro"
                mr={3}
              >
                <i className="fab fa-telegram" />
              </MKTypography>
              <MKTypography
                component="a"
                variant="h3"
                color="white"
                href="https://wa.me/79933251262"
                mr={3}
              >
                <i className="fab fa-whatsapp" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/*<Suspense fallback={renderLoader()}>*/}
        <Information />
        <Team />
        <Featuring />
        <Content />
        <Newsletter />
        {/*</Suspense>*/}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
