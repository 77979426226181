// @mui icons
// import Telegram from "@mui/icons-material/Telegram";
// import Phone from "@mui/icons-material/Phone";
// import WhatsApp from "@mui/icons-material/WhatsApp";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
// import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {},
  socials: [],
  // brand: {
  //   name: "RepaireR",
  //   image: logoCT,
  //   route: "/",
  // },
  // socials: [
  //   {
  //     icon: <WhatsApp />,
  //     link: "https://wa.me/79152715433",
  //   },
  //   {
  //     icon: <Telegram />,
  //     link: "https://t.me/Marselino",
  //   },
  //   {
  //     icon: <Phone />,
  //     link: "tel:+79031924437",
  //   },
  // ],
  menus: [
    {
      name: "Контакты",
      items: [
        { name: "Телефон", href: "tel:+79933251262" },
        { name: "Ватсап", href: "https://wa.me/79933251262" },
        { name: "Телеграм", href: "https://t.me/repairer_pro" },
      ],
    },
    {
      name: "Адрес Домодедово",
      items: [
        {
          name: "Домодедово, Барыбино, ул. 1-я Вокзальная, д.48 Этаж 2",
          href: "https://yandex.ru/maps/-/CCUoQOrDLA",
        },
        { name: "Режим работы 08:00-19:00", href: "tel:+79933251262" },
      ],
    },
    {
      name: "Адрес Саратов",
      items: [
        {
          name: "Саратов, Белоглинская улица, 21/31",
          href: "https://yandex.ru/maps/-/CCU1USb6SB",
        },
        { name: "Режим работы 10:00-20:00", href: "tel:+79933251262" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Сервисный центр. Copyright &copy; 2021 - {date}{" "}
      <MKTypography
        component="a"
        href="https://repairer.pro"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        RepaireR
      </MKTypography>
      .
    </MKTypography>
  ),
};
