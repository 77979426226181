export default function TelegramPOST(phone, username, text) {
  const data = "phone: " + phone + " name: " + username + " text: " + text;

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams({
      chat_id: "344019440",
      text: data,
    }),
  };
  fetch(
    "https://api.telegram.org/bot652993843:AAE7jfZLRK2GRTB3uB-iebyZctXtnjP6uSg/sendMessage",
    requestOptions
  );
}
