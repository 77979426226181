// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import notebook from "assets/images/notebook.webp";
// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="build_circle"
                    title="Что мы ремонтируем?"
                    description="Наши специалисты помогут вам в решении практически любой проблемы
                     с техникой разного вида. В том числе, мы можем выполнить ремонт: телевизоров,
                      компьютеров, ноутбуков, игровых приставок и многое другое."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="currency_ruble"
                    title="Сколько стоит ремонт?"
                    description="Стоимость будет зависеть от ряда факторов, в том числе: Типа техники.
                     Характера поломки. Необходимых комплектующих. Объема работ.
                      Но точную стоимость без диагностики сказать невозможно."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="construction"
                    title="Как мы работаем?"
                    description="Если у вас вышла из строя техника, то достаточно только оставить заявку на сайте
                     или позвонить нам по указанному номеру, Назначить время визита специалиста.
                      Дождаться приезда мастера. Все максимально просто, понятно и удобно для каждого."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="moped"
                    title="Почему мы?"
                    description="Демократичная цена. Высокий профессионализм опытных мастеров. Качественный и быстрый ремонт.
                    Для удобства наших клиентов у нас существует бесплатная доставка в удобное для Вас время."
                    // Вам достаточно позвонить или оставить заявку для нашего курьера, который приедет в кратчайшие сроки"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={notebook}
              title="Ремонт телевизоров ноутбуков"
              description="Мы предлагаем широкий спектр услуг, включая диагностику, замену комплектующих,
               восстановление операционной системы, устранение аппаратных и программных проблем,
                а также обслуживание и профилактику."
              action={{
                type: "internal",
                route: "/contact-us",
                color: "info",
                label: "Оставить заявку",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
