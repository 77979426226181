import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Content() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Спасаем вашу электронику: профессиональный и качественный ремонт любой сложности с
              гарантией и доступной ценой
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Наша компания специализируется на профессиональном ремонте телевизоров, компьютеров,
              ноутбуков и другой техники. Мы предлагаем быстрые, надежные и качественные услуги по
              восстановлению вашей техники. Независимо от марки или модели, наши эксперты с
              многолетним опытом справятся с любыми проблемами, с которыми вы столкнулись. Мы
              гарантируем качество выполняемых работ и предлагаем конкурентные цены. Оставьте свои
              проблемы с техникой нам и наслаждайтесь ее бесперебойной работой. Приходите к нам в
              мастерскую или свяжитесь с нами для получения дополнительной информации.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Content;
