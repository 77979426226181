export const SaratovService = [
  {
    url: "repair-tv",
    title: "Ремонт телевизоров в Саратове",
    text: `Добро пожаловать на страницу нашего сервисного центра по ремонту телевизоров в Саратове! Мы специализируемся на предоставлении качественных услуг по восстановлению работы вашего телевизора, обеспечивая стабильный и четкий просмотр любимых программ и фильмов.

Наша команда опытных специалистов по ремонту телевизоров обладает богатым опытом работы с различными типами и моделями телевизоров. Мы понимаем, что ваш телевизор – это не просто техническое устройство, а ключевой элемент вашего домашнего комфорта, поэтому мы делаем все возможное, чтобы вернуть его к жизни в кратчайшие сроки.

Наши услуги включают:

Диагностика неисправностей: Наша команда проводит тщательную диагностику вашего телевизора с использованием современного оборудования, чтобы определить причину проблемы. Мы выявляем все неисправности и предоставляем вам полный отчет о состоянии вашего устройства.

Ремонт и замена компонентов: Мы предлагаем широкий спектр услуг по ремонту телевизоров, включая замену экрана, ремонт блоков питания, замену конденсаторов и других компонентов. Мы используем только оригинальные запчасти и современное оборудование, чтобы гарантировать долговечность и надежность вашего телевизора.

Программное обеспечение и настройка: Мы проводим обновление программного обеспечения вашего телевизора, настройку цветовой схемы, контрастности и яркости, чтобы обеспечить наилучший пользовательский опыт.

Гарантия качества: Мы гарантируем высокое качество выполненных работ и предоставляем гарантию на все наши услуги. Ваше доверие - наша ценность, и мы делаем все возможное, чтобы вы остались довольны результатом.

Не теряйте время на поиск решений! Обратитесь к нам, и наши профессионалы помогут вам вернуть ваш телевизор к жизни. Сделайте первый шаг к качественному ремонту телевизора – свяжитесь с нами прямо сейчас!

`,
  },
  {
    url: "repair-pc",
    title: "Ремонт компьютеров в Саратове",
    text: `Добро пожаловать на страницу нашего сервисного центра по ремонту компьютеров в Саратове! Мы специализируемся на оказании высококачественных услуг по ремонту, настройке и обслуживанию компьютеров, обеспечивая надежное и эффективное функционирование вашего устройства.

Наша команда опытных специалистов по ремонту компьютеров обладает глубокими знаниями и большим опытом работы с различными типами и моделями компьютеров. Мы понимаем, что компьютер является важным инструментом для работы, обучения и развлечений, поэтому мы делаем все возможное, чтобы оперативно вернуть ваше устройство к жизни.

Наши услуги включают:

Диагностика неисправностей: Мы предоставляем тщательную диагностику компьютера, чтобы точно определить причину возникших проблем. Мы используем передовое оборудование и программное обеспечение, чтобы обеспечить точный анализ состояния вашего устройства.

Ремонт и замена компонентов: Мы предлагаем полный спектр услуг по ремонту компьютеров, включая замену или восстановление поврежденных компонентов, исправление программных ошибок, а также устранение проблем с жестким диском, оперативной памятью и другими элементами.

Обслуживание и профилактика: Мы проводим обслуживание и профилактику компьютеров, включая чистку от пыли, обновление драйверов и программного обеспечения, а также оптимизацию работы системы для повышения производительности и стабильности работы устройства.

Гарантия качества: Мы гарантируем высокое качество выполненных работ и предоставляем гарантию на все проведенные ремонтные работы. Мы стремимся к тому, чтобы каждый наш клиент остался доволен результатом и получил уверенность в надежности своего компьютера.

Не теряйте время на поиск решений! Обратитесь к нам, и наши профессионалы помогут вам вернуть ваш компьютер к жизни. Сделайте первый шаг к качественному ремонту компьютера – свяжитесь с нами прямо сейчас!`,
  },
  {
    url: "repair-notebook",
    title: "Ремонт ноутбуков в Саратове",
    text: `Добро пожаловать на страницу нашего сервисного центра по ремонту ноутбуков в Саратове! Мы специализируемся на ремонте и обслуживании ноутбуков всех марок и моделей, обеспечивая надежность и качество в каждом выполненном ремонте.

Наша команда опытных специалистов по ремонту ноутбуков оснащена современным оборудованием и глубокими знаниями, чтобы решить широкий спектр проблем, с которыми может столкнуться ваш ноутбук.

Наши услуги включают:

Диагностика и оценка проблемы: Мы проводим тщательную диагностику вашего ноутбука, чтобы выявить причину неисправности и предложить наиболее эффективное решение.

Ремонт и замена компонентов: Мы осуществляем ремонт и замену компонентов вашего ноутбука, включая клавиатуру, экран, аккумулятор, жесткий диск, оперативную память и другие элементы.

Программное обеспечение и настройка: Мы проводим установку и настройку программного обеспечения, включая операционные системы, драйверы и приложения, чтобы ваш ноутбук работал эффективно и без сбоев.

Гарантия качества: Мы гарантируем качество нашей работы и предоставляем гарантию на все выполненные услуги. Мы стремимся к тому, чтобы каждый наш клиент остался доволен результатом и имел уверенность в надежности своего ноутбука.

Не теряйте времени и продуктивности из-за неисправностей вашего ноутбука. Обратитесь к нам, и наши профессионалы сделают все возможное, чтобы ваш ноутбук был в отличном состоянии. Свяжитесь с нами прямо сейчас!

`,
  },
];
