/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import pc from "assets/images/tehnics/pc.webp";
import tv from "assets/images/tehnics/tv.webp";
import notebook from "assets/images/tehnics/laptop.webp";
import ps from "assets/images/tehnics/ps.webp";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h2" color="white">
              Ремонтируем технику любой сложности
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Мы предоставляем услуги ремонта не только телевизоров, но и других устройств, таких
              таких как фотоаппараты, игровые приставки и дроны. Наша команда специалистов имеет
              опыт работы с различными устройствами и готова решать любые технические проблемы.
              Обращайтесь к нам, и мы поможем вам вернуть ваше устройство к жизни
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={tv}
                name="Телевизоры"
                position={{ color: "info", label: "Мониторы" }}
                description="Мы предоставляем профессиональные услуги по ремонту телевизоров всех марок и моделей.
                 Замена матрицы"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={notebook}
                name="Ноутбуки"
                position={{ color: "info", label: "Моноблоки" }}
                description="Оригинальные запчасти и гарантия на проделанную работу.Чистка системы охлаждения."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={pc}
                name="Компьютеры"
                position={{ color: "info", label: "Фермы" }}
                description="Наш сервис быстро и надежно восстанавливает функциональность вашего компьютера. Апгрейд."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={ps}
                name="Игровые приставки"
                position={{ color: "info", label: "Консоли" }}
                description="Исправление аппаратных и программных проблем. Обновление ПО, профилактическая очистка"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
