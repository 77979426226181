// @mui material components
// import Icon from "@mui/material/Icon";

import PhoneIcon from "@mui/icons-material/Phone";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import BlogUs from "layouts/pages/landing-pages/blog";
import { blogs } from "./pages/LandingPages/Blog/blogs";
import PostPage from "./layouts/pages/landing-pages/blog/post";
// import InstallSatellite from "./pages/LandingPages/Blog/sputnik";
import { Icon } from "@mui/material";
import ErrorPage from "./layouts/pages/landing-pages/error";
import ServicePage from "./layouts/pages/landing-pages/service";
import { SaratovService } from "./pages/LandingPages/Services/Saratov/service";
import { DomodedovoService } from "./pages/LandingPages/Services/Domodedovo/service";
// import NotFound from "./pages/Error";
// import RepairTVBlog from "./layouts/pages/landing-pages/blog/tv";

const urlTV = "repair-tv";
const urlPC = "repair-pc";
const urlNB = "repair-notebook";
const urlSatellite = "install-satellite";
// const urlServiceTV = "tv-repair";
// const tv = blogs.find((o) => o.name === "repair-tv");
// console.log(tv);

export const blog = [
  {
    name: "Error",
    // icon: "",
    route: "/error-page",
    component: <ErrorPage />,
  },
  {
    name: "Ремонт тв",
    route: `/blog/${urlTV}`,
    component: <PostPage props={blogs.find((o) => o.name === urlTV)} />,
  },
  {
    name: "Ремонт пк",
    route: `/blog/${urlPC}`,
    component: <PostPage props={blogs.find((o) => o.name === urlPC)} />,
  },
  {
    name: "Ремонт ноутбука",
    route: `/blog/${urlNB}`,
    component: <PostPage props={blogs.find((o) => o.name === urlNB)} />,
  },
];
export const navBarRoutes = [
  {
    name: "Главная",
    // icon: "",
    route: "/",
    component: <AboutUs />,
  },
  {
    name: "Блог",
    // icon: "",
    route: "/blog",
    component: <BlogUs />,
  },

  // {
  //   name: "account",
  //   collapse: [
  //     {
  //       name: "sign in",
  //       route: "/pages/authentication/sign-in",
  //       component: <SignIn />,
  //     },
  //   ],
  // },
  {
    name: "Услуги",
    icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "Домодедово",
        description: "",
        // route: "/blog",
        component: <BlogUs />,
        dropdown: true,
        collapse: [
          {
            name: "Спутниковое оборудование",
            route: `/service/domodedovo/${urlSatellite}`,
            component: (
              <ServicePage props={DomodedovoService.find((o) => o.url === urlSatellite)} />
            ),
          },
          {
            name: "Ремонт телевизоров",
            route: `/service/domodedovo/${urlTV}`,
            component: <ServicePage props={DomodedovoService.find((o) => o.url === urlTV)} />,
          },
          {
            name: "Ремонт компьютеров",
            route: `/service/domodedovo/${urlPC}`,
            component: <ServicePage props={DomodedovoService.find((o) => o.url === urlPC)} />,
          },
        ],
      },
      {
        name: "Саратов",
        description: "",
        // route: "/blog",
        component: <BlogUs />,
        dropdown: true,
        collapse: [
          {
            name: "Ремонт компьютеров",
            route: `/service/saratov/${urlPC}`,
            component: <ServicePage props={SaratovService.find((o) => o.url === urlPC)} />,
          },
          {
            name: "Ремонт телевизоров",
            route: `/service/saratov/${urlTV}`,
            component: <ServicePage props={SaratovService.find((o) => o.url === urlTV)} />,
          },
          {
            name: "Ремонт ноутбуков",
            route: `/service/saratov/${urlNB}`,
            component: <ServicePage props={SaratovService.find((o) => o.url === urlNB)} />,
          },
        ],
      },
    ],
  },
  // {
  //   name: "Услуги",
  //   icon: <Icon>view_day</Icon>,
  //   collapse: [
  //     {
  //       name: "Спутниковое оборудование",
  //       route: `/service/${urlSatellite}`,
  //       component: <PostPage props={blogs.find((o) => o.name === urlSatellite)} />,
  //     },
  //     {
  //       name: "Ремонт телевизоров",
  //       route: `/service/${urlServiceTV}`,
  //       component: <ServicePage props={blogs.find((o) => o.name === urlServiceTV)} />,
  //     },
  //   ],
  // },
  {
    name: "Контакты",
    // icon: "",
    route: "/contact-us",
    component: <ContactUs />,
  },
  {
    name: "Позвонить",
    icon: <PhoneIcon />,
    href: "tel:+79933251262",
  },
];

export const routes = navBarRoutes.concat(blog);
