export const DomodedovoService = [
  {
    url: "install-satellite",
    title: "Установка спутникового оборудования в Домодедово",
    text: `Мы являемся опытными специалистами в области спутниковых систем и предоставляем
                профессиональные услуги по установке, настройке и обслуживанию спутникового
                оборудования. Наша команда экспертов способна обеспечить стабильный и качественный
                качественный сигнал для цифрового телевидения и радио. Мы работаем с различными
                спутниковых систем и оборудования, включая крупные спутниковые тарелки, малые
                антенны, системы для приема HDTV и многое другое. Наши услуги включают: Консультации
                Консультации и подбор оборудования: Наши специалисты помогут вам выбрать наиболее
                подходящее спутниковое оборудование, учитывая ваши потребности и бюджет.
                Профессиональная установка: Наша команда обладает богатым опытом в установке
                спутниковых систем любой сложности. Мы установим оборудование на крыше вашего дома,
                балконе или другом удобном месте с учетом оптимального направления и угла наклона.
                Настройка и тестирование: После установки мы произведем точную настройку
                спутникового оборудования для получения наилучшего качества сигнала. Проведем
                комплексное тестирование системы для уверенности в ее работоспособности.
                Обслуживание и ремонт: Мы предоставляем постоянную поддержку и обслуживание вашей
                спутниковой системы. В случае неисправностей или потребности в ремонте, наши
                специалисты оперативно реагируют и устраняют проблемы. Установка спутникового
                оборудования - это гарантия доступа к большому количеству телевизионных и радио
                каналов, а также возможность получить высококачественный сигнал даже в удаленных
                районах. Обратившись к нам, вы можете быть уверены в профессиональном подходе и
                качественном результате. с нами прямо сейчас, чтобы заказать установку спутникового
                оборудования и множеством интересных программ и развлечений от ведущих мировых
                поставщиков контента!`,
  },
  {
    url: "repair-tv",
    title: "Ремонт телевизоров в Домодедово: Качественное восстановление на дому",
    text: `Добро пожаловать на страницу нашего сервисного центра по ремонту телевизоров в Домодедово! Мы являемся ведущими специалистами в области телевизионного ремонта, предоставляя качественные услуги восстановления изображения и звука вашего телевизора.
Наша команда опытных техников специализируется на ремонте телевизоров различных марок и моделей, включая LED, LCD, плазменные и OLED телевизоры. Мы понимаем, как важно иметь надежный и работоспособный телевизор в вашем доме или офисе, и делаем все возможное, чтобы оперативно вернуть ваш телевизор к жизни.

Наши услуги включают:

Диагностика и оценка: Наша команда проводит тщательную диагностику неисправностей вашего телевизора, чтобы точно определить причину проблемы. Мы предоставляем вам полную информацию о состоянии вашего устройства и предлагаем оптимальные варианты ремонта.

Ремонт и замена компонентов: Мы предлагаем широкий спектр услуг по ремонту телевизоров, включая замену дисплея, платы управления, блоков питания и других компонентов. Мы используем только оригинальные запчасти и современное оборудование для максимальной эффективности и долговечности вашего телевизора.

Программное обеспечение и настройка: Мы также предлагаем услуги по обновлению программного обеспечения вашего телевизора, настройке цветовой схемы, контрастности и яркости, чтобы обеспечить наилучший пользовательский опыт.

Гарантия качества: Мы гарантируем высокое качество выполненных работ и даем гарантию на все проведенные ремонтные работы. Ваше доверие - наша ценность, и мы стремимся к полной удовлетворенности каждого нашего клиента.

Не теряйте время на поиск решений! Обратитесь к нам, и наши профессионалы помогут вам вернуть ваш телевизор к жизни. Сделайте первый шаг к качественному ремонту телевизора – свяжитесь с нами прямо сейчас!`,
  },
  {
    url: "repair-pc",
    title: "Ремонт компьютеров в Домодедово: Ваш надежный партнер в мире технологий",
    text: `В мире, где технологии играют ключевую роль в нашей повседневной жизни, надежный компьютер – это необходимость, а не роскошь. Однако, как и любое техническое устройство, компьютеры иногда нуждаются в ремонте. В такие моменты на помощь приходит наша команда профессиональных специалистов по ремонту компьютеров в Домодедово.
Мы – это коллектив опытных технических экспертов, специализирующихся на ремонте и обслуживании компьютеров различных марок и моделей. Наша цель – обеспечить вам быстрое и качественное восстановление работы вашего компьютера, чтобы вы могли продолжить свою работу или развлечения без лишних забот.
Наши услуги включают:

Диагностика проблемы: Наша команда проведет тщательную диагностику вашего компьютера, чтобы выявить причину возникшей проблемы. Мы используем передовое оборудование и программное обеспечение, чтобы обеспечить точный анализ состояния вашего устройства.

Ремонт и замена компонентов: Мы предлагаем полный спектр услуг по ремонту компьютеров, включая замену поврежденных или устаревших компонентов, восстановление операционной системы, и исправление программных ошибок.

Обслуживание и профилактика: Помимо ремонта, мы также предлагаем услуги по обслуживанию и профилактике компьютеров. Регулярная чистка от пыли, обновление драйверов и программного обеспечения, а также оптимизация работы системы позволят вашему компьютеру работать более эффективно и надежно.

Гарантия качества: Мы гарантируем высокое качество выполняемых работ и использование только оригинальных комплектующих. Ваше доверие - наш приоритет, и мы стремимся к полной удовлетворенности каждого нашего клиента.

Не ограничивайте себя проблемами с компьютером. Обратитесь к нам, и наша команда экспертов сделает все возможное, чтобы ваш компьютер был снова в отличной форме. Сделайте первый шаг к безопасной и эффективной работе вашего компьютера – свяжитесь с нами уже сегодня!`,
  },
];
