import { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import { routes } from "routes";
// import ErrorPage from "./layouts/pages/landing-pages/error";
// import NotFound from "./pages/Error";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        // if (route.route === "*") {
        //   return (
        //     <Route
        //       exact
        //       path={route.route}
        //       element={route.component}
        //       key={route.key}
        //       status={404}
        //     />
        //   );
        // }
        return (
          // eslint-disable-next-line react/jsx-key
          <Route
            // exact
            path={route.route}
            element={route.component}
            // key={route.key}
            // errorElement={<ErrorPage />}
          />
        );
      }

      return null;
    });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/notfound" replace />} />
      </Routes>
    </ThemeProvider>
  );
}
