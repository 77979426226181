// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
// import macbook from "assets/images/macbook.png";
import revendo from "assets/images/revendo.webp";
import { useEffect, useState } from "react";
import TelegramPOST from "service/request";
import { Alert, FormControl, Input, InputLabel, Snackbar } from "@mui/material";
import { TextMaskCustom } from "service/mask";

function Newsletter() {
  const [isDisabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({
    phone: "",
    username: "",
    text: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    TelegramPOST(inputs.phone, inputs.username, "заказ звонка");
    setOpen(true);
    setDisabled(true);
    inputs.phone = "";
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (inputs.phone.length > 15) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          {/* eslint-disable-next-line react/jsx-no-duplicate-props */}
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h2">
              Ремонтируем телевизоры и компьютеры в Домодедово, Климовск, Подольск и Саратов
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Не хотите тратить время на поездку в сервисный центр? Наша компания предлагает услуги
              по ремонту ноутбуков и телевизоров на дому. Наши квалифицированные специалисты быстро
              и эффективно устранят любую проблему, чтобы вы могли наслаждаться своей техникой у
              себя дома
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="formatted-text-mask-input">Номер телефона</InputLabel>
                  <Input
                    inputComponent={TextMaskCustom}
                    placeholder="(900)000-00-00"
                    type="tel"
                    label="Введите телефон..."
                    name="phone"
                    value={inputs.phone}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <MKButton
                  variant="gradient"
                  color="info"
                  sx={{ height: "100%" }}
                  disabled={isDisabled}
                  onClick={handleSubmit}
                >
                  Заказать звонок
                </MKButton>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
                    Заявка зарегистрирована!
                  </Alert>
                </Snackbar>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={revendo} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
