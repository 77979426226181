import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import { navBarRoutes } from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/reven.webp";
import TelegramPOST from "service/request";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import { TextMaskCustom } from "service/mask";
import { useEffect, useState } from "react";

function ContactUs() {
  const [isDisabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({
    phone: "",
    username: "",
    text: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    TelegramPOST(inputs.phone, inputs.username, inputs.text);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    window.location.href = "/";
  };
  useEffect(() => {
    if (inputs.phone.length > 15) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={navBarRoutes}
          // action={{
          //   type: "external",
          //   route: "tel:+79031924437",
          //   label: "89031924437",
          //   color: "info",
          // }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(90% - 2rem)"
            height="calc(100vh - 10rem)"
            borderRadius="lg"
            ml={3}
            mt={3}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Обратная связь
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Можете вызвать курьера и отправить поломаную технику в севрвисный центр с помощью
                формы обратной связи
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Ваше Имя"
                      InputLabelProps={{ shrink: true }}
                      name="username"
                      value={inputs.username || ""}
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="formatted-text-mask-input">Номер телефона</InputLabel>
                      <Input
                        type="tel"
                        // variant="standard"
                        // label="Номер телефона"
                        InputLabelProps={{ shrink: true }}
                        placeholder="(900)000-00-00"
                        name="phone"
                        value={inputs.phone}
                        fullWidth
                        onChange={handleChange}
                        inputComponent={TextMaskCustom}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="Какая техника сломалась?"
                      placeholder="Опишите кратко свою проблму до 250 символов"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      name="text"
                      value={inputs.text || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    disabled={isDisabled}
                    onClick={handleSubmit}
                  >
                    Отправить
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Заявка зарегистрирована"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ожидайте звонка от нашего специалиста.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ок
          </Button>
        </DialogActions>
      </Dialog>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
