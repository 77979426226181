/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Images
import haier from "assets/images/logos/brands/haier.svg";
import philips from "assets/images/logos/brands/philips.svg";
import lg from "assets/images/logos/brands/lg.svg";
import panasonic from "assets/images/logos/brands/panasonic.svg";
import sony from "assets/images/logos/brands/sony.svg";
import samsung from "assets/images/logos/brands/samsung.svg";

function Featuring() {
  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <Grid container spacing={3} sx={{ mb: 12 }}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={haier} alt="haier" width="40%" opacity={0.2} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={philips} alt="philips" width="50%" opacity={0.3} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={lg} alt="lg" width="40%" opacity={0.3} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={samsung} alt="samsung" width="50%" opacity={0.3} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={panasonic} alt="panasonic" width="50%" opacity={0.3} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={sony} alt="sony" width="50%" opacity={0.3} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={5234}
              separator=","
              title="Заявки"
              description="Обработаных заявок нашем сервисным центром"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={4700}
              separator=","
              suffix="+"
              title="Отремонтировано"
              description="Количество отремонтрованной техники"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={99}
              suffix=".9%"
              title="Клиентов"
              description="Счастливых и долволных наших клиентов"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
