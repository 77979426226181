import React from "react";
// import { useLocation } from "react-router-dom";
// import { getStatusCode } from "http-status-codes";
// import { getStatusText } from "http-status-codes";

function NotFound() {
  // const location = useLocation();

  // Устанавливаем статус код 404
  React.useEffect(() => {
    document.title = "Страница не найдена";
    document.status = "404";
  }, []);
  const status = 404;
  console.log(status);
  return (
    <div>
      <h1>404 - Страница не найдена</h1>
      <p>Страница по адресу {location.pathname} не существует.</p>
    </div>
  );
  // eslint-disable-next-line no-unreachable
}

export default NotFound;
